<script setup>
import TermsProvider from "@/providers/TermsProvider"
import { onMounted, reactive, ref } from "vue";
import { coordinator } from "@/store/store";
var MarkdownIt = require('markdown-it')

const provider = new TermsProvider()

const md = new MarkdownIt();

const data = reactive({
    content: "",
    loadedTerms: false
})

coordinator.changeHeaderState(true)

onMounted(() => {
    provider.fetch((result) => {
        if (!result) {
            return
        }
        data.content = md.render(result);
        data.loadedTerms = true
    })
})
</script>


<template>
    <div class="terms-wrapper" v-if="data.loadedTerms">
        <div class="terms" v-html="data.content"></div>
    </div>
</template>
<style lang="scss">
.terms-wrapper {
    display: flex;
    justify-content: center;
    font-weight: 600;

    

    @apply text-slate-300;

    .terms {
        text-align: left;
        border-radius: 12px;
        @apply w-2/3 p-10 bg-slate-500;
    }

    ul {
        li {
            list-style-type: circle;
            margin-left: 2rem;
            margin-bottom: 1rem;
        }
    }

    h1 {
        font-size: 2rem !important;
        @apply p-4 text-center;
    }

    h4 {
        font-size: 1.5rem;
        @apply p-4;

    }

    p {
        margin: 1rem 0;
    }

}
</style>