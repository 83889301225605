import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/views/Main'
import Terms from '@/views/Terms'
const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
