<script setup>
import { onMounted, reactive } from 'vue';
import LogoProvider from "@/providers/LogoProvider"

const props = defineProps(["station", "coordinator"])

const data = reactive({
    imageSrc: null
})

onMounted(() => {
    const imageName = props.station.image
    const provider = new LogoProvider()

    provider.fetch(imageName, (url) => {
        data.imageSrc = url 
    })
})


</script>
<template>
    <div class="column-wrapper">
        <img :src="data.imageSrc" />
        <h5>{{ station.name }}</h5>
    </div>
</template>

<style lang="scss" scoped>
.column-wrapper {
    display: grid;
    grid-template-rows: 130px 20px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    @apply bg-slate-900;
}

h5 {
    @apply text-slate-200;
    font-size: 14px;
    text-overflow: ellipsis;
}
</style>