export default class StationsController {
    

    constructor(provider, view, coordinator) {
        this.provider = provider
        this.view = view
        this.coordinator = coordinator
        this.coordinator.changeHeaderState(false)
    }

    init() {
        // this.view.dataSource = this.provider.fetch(stations)
        // this.view.inited = true
        this.provider.fetch((stations) => {
            this.view.dataSource = stations
            this.view.inited = true

        })
    }

    search(text) {
        let result = this.provider.search(text)
        if (result) {
            this.view.dataSource = result
        }
    }

    selectStation(station) {
        this.coordinator.changeStation(station)
    }

}