<script setup>
import { reactive, ref } from 'vue';
import StationsController from "@/controllers/StationsController"
import StationsProvider from "@/providers/StationsProvider"
import StationsCoordinator from "@/coordinators/StationCoordinator"

import Station from "@/components/Station.vue"
import { coordinator } from '@/store/store';
// import Search from '@/components/Search.vue';

const data = reactive({
    dataSource: [],
    inited: false
})
const searchText = ref('')
const provider = new StationsProvider()
const controller = new StationsController(provider, data, coordinator)



controller.init()

function selectStation(station) {
    coordinator.changeStation(station)
}

function keyUpHandler(event) {
    // console.log(searchText.value)
    // let result = provider.search(searchText.value)
    // if (result) {

    // }
    controller.search(searchText.value)
}


</script>

<template>
    <!-- <search v-model:search="searchText" @keyup="keyUpHandler"></search> -->
    <div id="stations-container">

        <station @click="selectStation(station)" class="column" v-if="data.inited" v-for="station in data.dataSource" :station="station"></station>
    </div>
</template>

<style lang="scss" scoped>

    #stations-container {
        padding: 2em;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 1em;
    }

    .column {
        @apply drop-shadow border-y-cyan-100;
        border-radius: 12px;
        border-width: 2px;
        overflow: hidden;
    }
</style>