<script setup>
import { onMounted, reactive, watch, ref } from "vue";
import { coordinator } from "@/store/store"
import AudioController from "@/controllers/AudioController"
import LogoProvider from "@/providers/LogoProvider";
const state = reactive({
    station: null,
})
coordinator.setAudioState(state)

const controller = new AudioController()
const provider = new LogoProvider()

const data = reactive({
    imageSrc: null
})

onMounted(() => {
    const player = document.getElementById("player")
    controller.setPlayer(player)
})

watch(
    () => state.station,
    (station) => {
        controller.changeStation(station.stream)
        provider.fetch(state.station.image, (url) => {
            data.imageSrc = url
        })
    }
)
</script>


<template>
    <div class="audio" controls>
        <div class="info-wrapper">
            <div class="info">
                    <img v-if="data.imageSrc" :src="data.imageSrc" />
                    <img v-if="!data.imageSrc" src="../assets/station_placeholder.svg" />
                <h5>{{ state.station?.name }}</h5>
            </div>
        </div>
        <audio controls id="player" autobuffer preload="metadata"></audio>
    </div>
</template>

<style lang="scss" scoped>
audio {
    width: 90%;
align-self: center;

}

h5 {
    @apply text-slate-100;
}

.audio {
    // display: grid;
    // width: 300px;
    // height: 120px;
    height: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    border-radius: 12px;

}

.info-wrapper {
    // display: grid;
    // grid-template-rows: 1fr;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px;
}

img {
    // width: 100px;
    // height: 100px;
    object-fit: contain;
    border: none;
    max-width: 90px;
    max-height: 90px;
    height: 100%;
    width: auto;
    border-width: 1px;
        border-radius: 12px;
        @apply border-b-slate-800;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>