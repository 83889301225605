<script setup>

import AudioPlayer from '@/components/AudioPlayer.vue';
import Search from '@/components/Search.vue';
import { coordinator } from '@/store/store';
import { reactive } from 'vue';
const state = reactive({
    termsState: true
})

coordinator.setHeaderState(state)





</script>

<template>
    <div class="header">
        <div class="left-side side">
            <div class="logo">
            <img src="../assets/radioicon_1.png" />
            </div>
        </div>
        <div class="middle">
            <h1 class="title">HUN Radio Station Link Collection`s</h1>
            <search v-if="!state.termsState"></search>
        </div>
        <div class="right-side side">
            <AudioPlayer v-if="!state.termsState"></AudioPlayer>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.header {
    position: fixed;
    height: 150px;
    z-index: 100;
    width: 100vw;
    top: 0;
    left: 0;
    backdrop-filter: blur(12px);
    background-color: rgba(255,255,255,0.8);
    background: linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.1) 65%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1) 100%);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    padding: 10px;
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    grid-template-rows: 150px;

}

h1 {
    font-size: 2em;
    @apply text-slate-400;
}

.side {
}

.middle {
    display: grid;
    grid-template-rows: 1fr 60px;

}

.logo {
    background: transparent;
    padding: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
}

.side {
    img {
        // width: 150px;
        // height: auto;
        border-width: 1px;
        border-radius: 12px;
        @apply border-b-slate-800;
    }
}
.right-side {
//     background-color: red;
// display: flex;
// justify-content: space-around;
// flex-direction: column;
// height: 100%;
padding: 10px;
}

</style>
