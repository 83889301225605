export default class AudioPlayer {


    setPlayer(player) {
        this.player = player
        this.player.onloadedmetadata = (event) => {
            this.loadedMetaData(event)
        }
    }

    loadedMetaData(event) {
    }   

    changeStation(streamSource) {
        if (!streamSource) {
            return
        }
        this.stop()
        this.player.src = streamSource
        this.play()
        // console.log("hmm", streamSource)

    }

    stop() {
        this.player.pause()
        this.player.src = null
    }

    pause() {
        this.player.pause()
    }

    play() {
        try {
            this.player.play()
        } catch (error) {
            
        }
       
    }
}