
export default class StationCoordinator {

    constructor() {
        this.audioState = null
        this.headerState = null
    }

    changeStation(station) {
        this.audioState.station = station
        this.audioState.title = station.stream
    }

    setAudioState(state) {
        this.audioState = state
    }

    setHeaderState(state) {
        this.headerState = state
    }

    changeHeaderState(terms) {
        if (!this.headerState) {
            return
        }
        this.headerState.termsState = terms
    }

}