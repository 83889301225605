import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore/lite';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyD2vjQizsJ__xpJQVvXiIK4UcRKyvqrYQ4",
    authDomain: "radiostations-d88bd.firebaseapp.com",
    projectId: "radiostations-d88bd",
    storageBucket: "radiostations-d88bd.appspot.com",
    messagingSenderId: "124544227259",
    appId: "1:124544227259:web:f0fd5458b33be1fe82d69e",
    measurementId: "G-0R0TSHXPEX"
  };


export default class AppConfig {

    


    static shared = new this();

    constructor() {
        return this.constructor.shared
    }

    getFirebaseApp() {
        if (!this.firebaseApp) {
            this.firebaseApp = initializeApp(firebaseConfig);
        }
        return this.firebaseApp
    }

    getAnalyticsApp() {
        if (!this.analytics) {
            this.analytics = getAnalytics(app);
        }
        return this.analytics
    }

    getFirebaseDB() {
        if (!this.db) {
            this.db = getFirestore(this.firebaseApp)
        }
        return this.db
    }

    getStorage() {
        if (!this.storage) {
            this.storage = getStorage()
        }
        return this.storage
    }

}