import {ref, getDownloadURL } from "firebase/storage";
import AppConfig from "@/Config/AppConfig";

export default class LogoProvider {

    fetch(imageName, callback) {

        const storage = AppConfig.shared.getStorage()

        const name = "logos/" + imageName
        const reference = ref(storage, name);

        getDownloadURL(reference).then((url) => {
            callback(url)
        })
        .catch((error) => {
            console.log(error.code)

            switch (error.code) {
                case 'storage/object-not-found':
                  // File doesn't exist
                  break;
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;
          
                // ...
          
                case 'storage/unknown':
                  // Unknown error occurred, inspect the server response
                  break;
              }
        });
    }
}