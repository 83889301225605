// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyD2vjQizsJ__xpJQVvXiIK4UcRKyvqrYQ4",
    authDomain: "radiostations-d88bd.firebaseapp.com",
    projectId: "radiostations-d88bd",
    storageBucket: "radiostations-d88bd.appspot.com",
    messagingSenderId: "124544227259",
    appId: "1:124544227259:web:f0fd5458b33be1fe82d69e",
    measurementId: "G-0R0TSHXPEX"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export default class StationsProvider {


    constructor() {
        this.dataSource = null
    }

    async fetch(callback) {
        const stations = collection(db, 'stations');
        const stationsSnapshot = await getDocs(stations);
        const stationList = stationsSnapshot.docs.map(doc => {
            let station = doc.data()
            if (station.image) {
                station.imageURL = "http://46.101.236.40:7700/station/logo/" + station.image
            }
            return station
        });
        let sorted = stationList.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
        })
        this.dataSource = sorted
        callback(sorted)
    }

    search(text) {
        if (!text || text == "") {
            return this.dataSource
        }

        if (text.length < 3) {
            return null
        }

        return this.dataSource.filter(station => {
            return station.name.toLowerCase().localeContains(text.toLowerCase())
        })
    }


}

String.prototype.localeContains = function(sub) {
    if(sub.length>this.length) return false;
    let ascii = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return ascii(this).includes(ascii(sub));
}
  