import {ref, getDownloadURL } from "firebase/storage";
import axios from "axios"
import AppConfig from "@/Config/AppConfig";

export default class TermsProvider {

    fetch(callback) {
        const storage = AppConfig.shared.getStorage()
        const pathReference = ref(storage, 'terms.md');

        this.generateURL(pathReference, (url) => {
            this.downloadTerms(url, callback)
        })
    }

    generateURL(reference, callback) {
        getDownloadURL(reference)
        .then((url) => {
            callback(url)
        })
        .catch((error) => {
            callback(null)
        });
    }

    downloadTerms(url, callback) {
          axios.get(url).then(response => {
            callback(response.data)
          }).catch(error => {
            callback(null)
          })
    }

}